import { FC } from "react";
import { Form, useFetcher, useLocation } from "@remix-run/react";
import { Box } from "@mui/material";

type AddToWishlistFormProps = {
  children: React.ReactElement;
  productId: string;
};

const AddToWishlistForm: FC<AddToWishlistFormProps> = ({ children, productId }) => {
  let location = useLocation();
  const fetcher = useFetcher();
  return (
    <Box sx={{ width: "100%" }}>
      <fetcher.Form method="post" action="/wishlist" id="add-to-wishlist-form">
        <input
          type="hidden"
          name="_action"
          value="add-to-wishlist"
        />
        <input
          key={location.pathname + location.search}
          defaultValue={location.pathname + location.search}
          type="hidden"
          name="redirect"
        />
        <input
          key={productId}
          defaultValue={productId}
          type="hidden"
          name="product-id"
        />
        {children}
      </fetcher.Form>
    </Box>
  );
}

export default AddToWishlistForm;