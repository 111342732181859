/* eslint-disable react-hooks/exhaustive-deps */
import { AddShoppingCart, Favorite, RemoveRedEye } from "@mui/icons-material";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import { Box, Button, Chip, IconButton, styled, Tooltip } from "@mui/material";
import { Link, useNavigation } from "@remix-run/react";
import { FC, useCallback, useState } from "react";
import BazaarCard from "~/components/BazaarCard";
import { AddToCartForm } from "~/components/cart";
import ProductViewDialog from "~/components/products/ProductViewDialog";
import { H3, Span } from "~/components/Typography";
import { Product } from "~/models";
import { useRootLoader } from "~/utils/use-root-loader";
import { formatCurrency } from "~/utils/utils";
import BazaarImage from "../BazaarImage";
import { FlexBetween, FlexBox } from "../flex-box";
// import { AddToWishlistForm } from "../wishlist";
import { AddToWishlistForm } from "../wishlist";
import ProductLabels from "../product-labels/ProductLabels";
import wishlistToast from "../toast/wishlistToast";

export const StyledBazaarCard = styled(BazaarCard)(() => ({
  height: "100%",
  margin: "auto",
  display: "flex",
  overflow: "hidden",
  borderRadius: "10px",
  position: "relative",
  flexDirection: "column",
  justifyContent: "space-between",
  transition: "all 250ms ease-in-out",
  "& .MuiChip-label": {
    display: "flex",
    alignItems: "center",
    paddingLeft: "5px",
    paddingRight: "5px",
  },
  "& .content": {
    width: "15px",
    transition: "all 0.3s ease-out",
  },
  "& .discount-controller": {
    marginBottom: "5px",
    paddingRight: "8px",
    paddingLeft: "8px",
  },
  "& .label-controller": { marginBottom: "5px" },
  "& .label-controller-hovered": { marginBottom: "5px" },
  "& .initial": { display: "flex", justifyContent: "center" },
  "& .hovered": { display: "none", whiteSpace: "initial", lineHeight: "10px" },
  "&:hover": {
    "& .controller": { left: 8 },
    "& .cart-controller": { transform: "translateY(-100%)" },
    "& .content": { width: "64px" },
    "& .initial": { display: "none" },
    "& .hovered": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    // "& .brand-controller": { transform: "translateY(100%)" },
    // "& .discount-controller": { transform: "translateY(100%)" },
  },
}));

export const ImageWrapper = styled(Box)(({ theme }) => ({
  textAlign: "center",
  position: "relative",
  display: "inline-block",
  transition: "all 250ms ease-in-out",
  [theme.breakpoints.down("sm")]: { display: "block" },
  // "&:hover": {
  //   "& .controller": { right: 1 },
  //   "& .cart-controller": { top: 272, opacity: 1 }
  // },
}));

export const LabelWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  position: "absolute",
  top: "8px",
  right: "8px",
  transition: "all 0.3s ease-out",
}));

const StyledChip = styled(Chip)(() => ({
  zIndex: 1,
  // top: "5px",
  // right: "5px",
  paddingLeft: 0,
  paddingRight: 0,
  fontWeight: 600,
  fontSize: "11px",
  // position: "absolute",
  borderRadius: "5px",
  transitionProperty: "transform",
  transitionDuration: "0.3s",
  transitionTimingFunction: "ease-in-out",
}));

const HoverWrapper = styled(FlexBetween)(({ theme }) => ({
  bottom: 0,
  width: 25,
  left: -30,
  height: 68,
  overflow: "hidden",
  borderRadius: "5px",
  position: "absolute",
  flexDirection: "column",
  transition: "left 0.3s ease-in-out",
  "& span": {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "10px 0px",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": { cursor: "pointer" },
  },
  "& a": {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:hover": { cursor: "pointer" },
  },
  "& svg": { fontSize: 18 },
}));

const HoverCartWrapper = styled(FlexBetween)(({ theme }) => ({
  backgroundColor: "#2D68C4",
  // opacity: 0,
  height: 28,
  width: "100%",
  zIndex: 1,
  bottom: 102,
  position: "absolute",
  flexDirection: "row",
  transitionProperty: "transform",
  transitionDuration: "0.3s",
  transitionTimingFunction: "ease-in-out",
  "& span": {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "10px 0px",
    "&:hover": { cursor: "pointer" },
  },
  "& a": {
    width: "100%",
    height: "100%",
    display: "flex",
    "&:hover": { cursor: "pointer" },
  },
  "& svg": { fontSize: 18 },
}));
const BrandWrapper = styled(FlexBetween)(({ theme }) => ({
  // opacity: 0,
  // height: 28,
  // width: "50%",
  zIndex: 1,
  top: "8px",
  left: "8px",
  position: "absolute",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  flexDirection: "row",
  // transitionProperty: "transform",
  // transitionDuration: "0.3s",
  // transitionTimingFunction: "ease-in-out",
  "& span": {
    width: "100%",
    height: "100%",
    display: "flex",
    padding: "10px 0px",
    "&:hover": { cursor: "pointer" },
  },
  "& img": {
    // width: "48px",
    // height: "48px",
    display: "flex",
    // "&:hover": { cursor: "pointer" },
  },
  "& svg": { fontSize: 18 },
}));

export const ContentWrapper = styled(Box)(() => ({
  width: "100%",
  height: "100%",
  padding: "1rem",
  zIndex: 2,
  backgroundColor: "white",
  "& .title, & .categories": {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
}));

const DescriptionWrapper = styled(Box)(() => ({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  lineHeight: "20px",
  height: "20px",
  fontWeight: "400",
}));

const QtyButton = styled(Button)(() => ({
  height: 28,
  width: 50,
  backgroundColor: "#3B68B2",
  color: "white",
  borderRadius: 0,
}));

const CartButton = styled(Button)(() => ({
  height: 28,
  width: "100%",
  textAlign: "center",
  backgroundColor: "#2D68C4",
  color: "white",
  borderRadius: 0,
}));

const CartIcon = styled(Box)(() => ({
  color: "white",
  // transform: "translateY(100%)",
  transition: "transform 0.3s ease-in-out",
}));

const QtySpan = styled(Box)(() => ({
  backgroundColor: "#3B68B2",
  padding: "4px 10px",
  color: "white",
  height: 28,
  textAlign: "center",
  justifyContent: "center",
}));

// ========================================================
type ProductCardProps = {
  product: Product;
};
// ========================================================

const ProductCard: FC<ProductCardProps> = ({ product }) => {
  const { isCustomerLoggedIn } = useRootLoader();
  const [openModal, setOpenModal] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [addQty, setAddQty] = useState(1);
  const [hoveringOnText, setHoveringOnText] = useState(false);

  // const toggleIsFavorite = () => setIsFavorite((fav) => !fav);
  const toggleDialog = useCallback(() => setOpenModal((open) => !open), []);

  const handleAddQty = () => {
    let amount = addQty + 1;
    setAddQty(amount);
  };
  const handleRemoveQty = () => {
    if (addQty === 1) return;
    else {
      let amount = addQty - 1;
      setAddQty(amount);
    }
  };
  const handleMouseEnter = () => {
    setHoveringOnText(true);
  };
  const handleMouseLeave = () => {
    setHoveringOnText(false);
  };
  const handleClick = () => {
    if (isCustomerLoggedIn) {
      wishlistToast();
    }
  };
  let navigation = useNavigation();
  let submitDisabled = navigation.state !== "idle";

  const addToCart = hoveringOnText ? (
    <AddShoppingCart fontSize="small" />
  ) : (
    "Сагслах"
  );
  const discount = product.specialPrice
    ? (((product.price - product.specialPrice) / product.price) * 100).toFixed()
    : 0;

  const brandName = product.brandName ? product.brandName : "";
  const discountedPrice = discount !== 0 ? product.specialPrice : product.price;

  let shortDesc;
  if (product.shortDescription) {
    shortDesc = product.shortDescription.split(" ").splice(3).join(" ");
  }
  let unableToSell: boolean = false;
  let disabledAddButton: boolean = false;
  let buttonQty: number;
  let currentQty: number;
  let notClickaddButton: boolean = true;
  let showQtyMsg = false;
  const { cart } = useRootLoader();
  currentQty = Number(product.salableQty) - addQty;
  cart.items.map((cartItem) => {
    let cartQty: number;
    if (cartItem && cartItem.sku === product.sku) {
      cartQty = product.qty - cartItem.quantity;
      buttonQty = product.qty - cartItem.quantity - 1;
      if (cartQty >= 0 && cartQty <= 3) {
        showQtyMsg = true;
      }
      if (cartQty < 0) {
        showQtyMsg = true;
        unableToSell = true;
      }
      if (addQty > cartQty) {
        showQtyMsg = true;
        unableToSell = true;
        notClickaddButton = false;
        disabledAddButton = true;
      }
      if (addQty > buttonQty) {
        showQtyMsg = true;
        disabledAddButton = true;
      }
    }
  });

  if (notClickaddButton) {
    if (currentQty >= 0 && currentQty < 3) {
      showQtyMsg = true;
    }
    if (currentQty < 0) {
      unableToSell = true;
      showQtyMsg = true;
    }
    if (addQty > product.qty) {
      showQtyMsg = true;
      unableToSell = true;
      disabledAddButton = true;
    }
    if (addQty > product.qty - 1) {
      showQtyMsg = true;
      disabledAddButton = true;
    }
  }

  const blur = product.attributes.find(
    (item) => item.label === "Согтууруулах ундаа"
  );
  const isBlur = blur ? (blur.value === "Yes" ? true : false) : false;

  const isBusinessCard = product.priceTiers
    ? product.priceTiers.some((item) => item.quantity == 1)
    : false;
  // const isBusinessCard = false;
  const businessCardPrice = product.priceTiers
    ? product.priceTiers.find((item) => item.quantity == 1)
    : null;

  let date1 = new Date(product.specialToDate);
  let date2 = new Date();

  // To calculate the time difference of two dates
  let differenceInTime = date1.getTime() + 1000 * 3600 * 24 - date2.getTime();

  // To calculate the no. of days between two dates
  let differenceInDays = Math.round(differenceInTime / (1000 * 3600 * 24));
  // console.log(Difference_In_Days);
  let differenceInHours = Math.round(differenceInTime / (1000 * 3600));

  const hasGift = product.giftProducts
    ? product.giftProducts?.length !== 0
      ? product.giftProducts[0].sku !== product.sku
        ? true
        : false
      : false
    : false;

  const giftImage =
    hasGift && product.giftProducts && product.giftProducts.length !== 0
      ? product.giftProducts![0].defaultImage
      : "";

  // console.log("*******************");
  // console.log(date1.getTime() + 1000 * 3600 * 24);
  // console.log(differenceInDays);
  return (
    <StyledBazaarCard>
      {product.brandImage && (
        <BrandWrapper className="brand-controller">
          <Link to={`/s?brand=${product.brandId!}`}>
            <BazaarImage
              src={product.brandImage}
              alt={brandName}
              sx={{
                width: { xs: "30px", sm: "48px" },
                height: { xs: "30px", sm: "48px" },
              }}
            />
          </Link>
        </BrandWrapper>
      )}
      <ImageWrapper>
        {/* !FIXME: Fix discount */}
        <LabelWrapper>
          {hasGift && (
            <Box
              sx={{
                border: "1px solid #95B3E1",
                borderRadius: "5px",
                mb: "5px",
                backgroundColor: "white",
                display: "flex",
                justifyContent: "center",
                p: "1px",
              }}
            >
              <BazaarImage
                src={"/assets/images/icons/giftRibbon.png"}
                sx={{
                  position: "absolute",
                  top: "-5px",
                  width: "21px",
                  // left: "50%",
                  // transform: "translateX(-50%)",
                }}
              />
              <BazaarImage
                src={giftImage}
                alt="бэлэг"
                sx={{ width: "50px", height: "50px" }}
              />
            </Box>
          )}
          {discount !== 0 && !isBusinessCard && (
            <StyledChip
              className="discount-controller"
              sx={{
                border: "1px solid #E10600",
                color: "#E10600",
                backgroundColor: "#FFF",
                fontSize: "13px",
              }}
              variant="outlined"
              size="small"
              label={discount === "0" ? `< 1%` : `-${discount}%`}
            />
          )}

          <ProductLabels product={product} intro={false} />
        </LabelWrapper>

        <Link to={`${product.url}`} preventScrollReset={true}>
          <BazaarImage
            alt={product.title}
            height="100%"
            width="100%"
            src={product.defaultImage}
            className={isBlur ? "blur" : ""}
            sx={{ px: 4.5, pt: 3.5 }}
          />
        </Link>
      </ImageWrapper>

      <HoverWrapper
        className="controller"
        sx={{
          top: !!product.brandImage ? 56 : 0,
          display: { md: "flex", xs: "none" },
        }}
      >
        <Tooltip title="Үзэх" placement="left" sx={{ paddingTop: "10px" }}>
          <IconButton onClick={toggleDialog}>
            <RemoveRedEye color="disabled" fontSize="small" />
          </IconButton>
        </Tooltip>
        {isFavorite ? (
          <Favorite color="primary" fontSize="small" />
        ) : (
          <AddToWishlistForm productId={product.sku}>
            <Tooltip title="Хадгалах" placement="left">
              <Button
                type="submit"
                style={{ padding: "4px" }}
                onClick={handleClick}
              >
                <FavoriteBorder fontSize="small" color="disabled" />
              </Button>
            </Tooltip>
          </AddToWishlistForm>
        )}
      </HoverWrapper>
      {product.sku !== "1202453" &&
        product.sku !== "C100225" &&
        product.brandName !== "ECO OIL" && (
          <HoverCartWrapper
            className="cart-controller"
            sx={{ display: { md: "flex", xs: "none" } }}
          >
            <FlexBox>
              <QtyButton onClick={handleRemoveQty} disabled={addQty === 1}>
                -
              </QtyButton>
              <QtySpan>{addQty}</QtySpan>
              <QtyButton onClick={handleAddQty} disabled={disabledAddButton}>
                +
              </QtyButton>
            </FlexBox>
            <FlexBox
              alignItems="center"
              className="add-cart"
              flexDirection="column"
              justifyContent="space-between"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              sx={{ width: "100%" }}
            >
              <AddToCartForm productId={product.sku} quantity={addQty}>
                <CartButton
                  color="primary"
                  variant="contained"
                  disabled={submitDisabled || unableToSell}
                  type="submit"
                >
                  Сагслах
                </CartButton>
              </AddToCartForm>
            </FlexBox>
          </HoverCartWrapper>
        )}

      <ProductViewDialog
        openDialog={openModal}
        handleCloseDialog={toggleDialog}
        product={product}
        canAddToCart={submitDisabled || unableToSell}
      />

      <ContentWrapper sx={{ px: 0, pt: 0 }}>
        {differenceInDays > 0 ? (
          <Box sx={{ height: "20px", mb: 1 }}>
            {discount !== 0 && !isBusinessCard && (
              <Box
                sx={{
                  background: "#E10600",
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: { md: "12px", xs: "10px" },
                  letterSpacing: { xs: "-0.8px", md: "normal" },
                  pl: "1rem",
                }}
              >
                Хямдрал дуусахад{" "}
                <Span sx={{ fontSize: { md: "14px", xs: "12px" } }}>
                  {differenceInDays}
                  {/* {countdown("")} */}
                </Span>{" "}
                өдөр
              </Box>
            )}
          </Box>
        ) : (
          <Box sx={{ height: "20px", mb: 1 }}>
            {discount !== 0 && !isBusinessCard && (
              <Box
                sx={{
                  background: "#E10600",
                  color: "#FFF",
                  fontWeight: "600",
                  fontSize: { md: "12px", xs: "10px" },
                  letterSpacing: { xs: "-0.8px", md: "normal" },
                  pl: "1rem",
                }}
              >
                Хямдрал дуусахад{" "}
                <Span sx={{ fontSize: { md: "14px", xs: "12px" } }}>
                  {differenceInHours}
                </Span>{" "}
                цаг
              </Box>
            )}
          </Box>
        )}

        <FlexBox sx={{ pl: "1rem" }}>
          <Box flex="1 1 0" minWidth="0px" mr={1}>
            <Link to={`${product.url}`}>
              <H3
                mb={0.5}
                title={product.title}
                fontWeight="600"
                className="title"
                color="text.secondary"
                textTransform="uppercase"
                sx={{ fontSize: { md: "14px", xs: "12px" }, pt: 1 }}
              >
                {product.title}
              </H3>
            </Link>

            <FlexBox>
              <DescriptionWrapper
                sx={{ fontSize: { md: "13px", xs: "12px" }, fontWeight: "400" }}
              >
                {shortDesc}
              </DescriptionWrapper>
            </FlexBox>

            {product.sku !== "1202453" && product.sku !== "C100225" && (
              <FlexBox alignItems="baseline" gap={1} mt={1.5}>
                <Box
                  fontWeight="600"
                  color="primary.main"
                  sx={{ fontSize: { md: "16px", xs: "12px" } }}
                >
                  {isBusinessCard
                    ? formatCurrency(businessCardPrice?.price!)
                    : formatCurrency(discountedPrice)}
                </Box>
                {isBusinessCard ? (
                  <Box
                    display={"flex"}
                    color="grey.700"
                    fontWeight="600"
                    sx={{
                      background:
                        "linear-gradient(to top left, #fff calc(50% - 1.5px), #E10600 , #fff calc(50% + 1.5px) )",
                      height: { md: "12px", xs: "8px" },
                      alignItems: "center",
                      fontSize: { md: "14px", xs: "10px" },
                    }}
                  >
                    {formatCurrency(discountedPrice)}
                  </Box>
                ) : null}
                {!!product.specialPrice && !isBusinessCard ? (
                  <Box
                    display={"flex"}
                    color="grey.700"
                    fontWeight="600"
                    sx={{
                      background:
                        "linear-gradient(to top left, #fff calc(50% - 1.5px), #E10600 , #fff calc(50% + 1.5px) )",
                      height: { md: "12px", xs: "8px" },
                      alignItems: "center",
                      fontSize: { md: "14px", xs: "10px" },
                    }}
                  >
                    {isBusinessCard
                      ? formatCurrency(discountedPrice)
                      : formatCurrency(product.price)}
                  </Box>
                ) : null}
              </FlexBox>
            )}
          </Box>
        </FlexBox>
      </ContentWrapper>
    </StyledBazaarCard>
  );
};

export default ProductCard;
