import { Grid, IconButton } from "@mui/material";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";

export default function wishlistToast() {
  setTimeout(() => {
    toast.success(
      (t) => (
        <Grid container p={1}>
          <IconButton
            style={{
              height: "18px",
              width: "18px",
              right: "5px",
              top: "5px",
              position: "absolute",
            }}
            onClick={() => {
              toast.dismiss(t.id);
            }}
          >
            <CloseIcon
              style={{
                height: "18px",
                width: "18px",
              }}
            />
          </IconButton>
          Амжилттай хадгаллаа
        </Grid>
      ),
      {
        duration: 3000,
      }
    );
  }, 2000);
}
