import { Add, Close, Remove } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import BazaarButton from "~/components/BazaarButton";
import BazaarImage from "~/components/BazaarImage";
import Carousel from "~/components/carousel/Carousel";
import { H1, H2, H4, Paragraph } from "~/components/Typography";
import { FC } from "react";
import { Product, ProductMedia } from "~/models";
import { AddToCartForm } from "../cart";
import { formatCurrency } from "~/utils/utils";
import { Link } from "@remix-run/react";
import { FlexBox } from "../flex-box";

const ContentWrapper = styled(Box)(({ theme }) => ({
  "& .carousel:hover": {
    cursor: "pointer",
    "& .carousel__back-button": { opacity: 1, left: 10 },
    "& .carousel__next-button": { opacity: 1, right: 10 },
  },
  "& .carousel__next-button, & .carousel__back-button": {
    opacity: 0,
    boxShadow: "none",
    transition: "all 0.3s",
    background: "transparent",
    color: theme.palette.primary.main,
    ":disabled": { color: theme.palette.grey[500] },
    ":hover": {
      color: theme.palette.primary.main,
      backgroundColor: "transparent",
    },
  },
  "& .carousel__back-button": { left: 0 },
  "& .carousel__next-button": { right: 0 },
}));

// =====================================================
type ProductViewDialogProps = {
  product: Product;
  openDialog: boolean;
  handleCloseDialog: () => void;
  canAddToCart?: boolean;
};
// =====================================================

const ProductViewDialog: FC<ProductViewDialogProps> = (props) => {
  const { product, openDialog, handleCloseDialog, canAddToCart } = props;

  const discount = product.specialPrice
    ? (((product.price - product.specialPrice) / product.price) * 100).toFixed()
    : 0;

  const discountedPrice = discount !== 0 ? product.specialPrice : product.price;

  return (
    <Dialog
      open={openDialog}
      maxWidth="md"
      fullWidth
      onClose={handleCloseDialog}
      sx={{ zIndex: 1501 }}
    >
      <DialogContent sx={{ width: "100%" }}>
        <ContentWrapper>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              {product.medias.length !== 0 ? (
                <Carousel
                  totalSlides={product.medias?.length}
                  visibleSlides={1}
                  infinite={true}
                >
                  {product.medias?.map((item, index: number) => (
                    <BazaarImage
                      sx={{ mx: "auto" }}
                      key={index}
                      src={item.thumbnail}
                    />
                  ))}
                </Carousel>
              ) : (
                <BazaarImage sx={{ mx: "auto" }} src={product.defaultImage} />
              )}
            </Grid>

            <Grid item md={6} xs={12} alignSelf="center">
              <H2>{product.title}</H2>

              <Paragraph py={1} color="grey.500" fontWeight={600} fontSize={13}>
                <Link to="/">{product.brandName}</Link>
              </Paragraph>
              <FlexBox alignItems="center" gap={1} mt={1.5}>
                <Box fontWeight="600" color="primary.main">
                  <H2>{formatCurrency(discountedPrice)}</H2>
                </Box>

                {!!product.specialPrice && (
                <Box
                display={"flex"}
                color="grey.700"
                fontWeight="600"
                sx={{
                  background:
                    "linear-gradient(to top left, #fff calc(50% - 1.5px), #E10600 , #fff calc(50% + 1.5px) )",
                  height: { md: "12px", xs: "8px" },
                  alignItems: "center",
                  fontSize: { md: "16px", xs: "10px" },
                }}
              >
                {formatCurrency(product.price)}
              </Box>
                )}
              </FlexBox>

              {/* <FlexBox alignItems="center" gap={1}>
                <BazaarRating color="warn" fontSize="1.25rem" value={4} readOnly />
                <H6 lineHeight="1">(50)</H6>
              </FlexBox> */}

              <Paragraph
                my={2}
                dangerouslySetInnerHTML={{ __html: product.shortDescription }}
              >
                {/* {product.shortDescription} */}
              </Paragraph>

              <Divider sx={{ mb: 2 }} />
              <FlexBox>
                <AddToCartForm
                  productId={product.sku}
                  quantity={1}
                  isFull={false}
                >
                  <BazaarButton
                    size="large"
                    color="primary"
                    variant="contained"
                    disabled={canAddToCart}
                    onClick={handleCloseDialog}
                    type="submit"
                    sx={{ height: 45 , width:"150px" }}
                  >
                    Сагслах
                  </BazaarButton>
                </AddToCartForm>
                <Link to={`${product.url}`}>
                  <BazaarButton
                    color="primary"
                    size="large"
                    variant="outlined"
                    onClick={handleCloseDialog}
                    sx={{ height: 45, ml: 2 , width:"150px"}}
                  >
                    Дэлгэрэнгүй
                  </BazaarButton>
                </Link>
              </FlexBox>
              {/* {!cartItem?.qty ? (
                <BazaarButton
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={handleCartAmountChange(1)}
                  sx={{ height: 45 }}
                >
                  Add to Cart
                </BazaarButton>
              ) : (
                <FlexBox alignItems="center">
                  <BazaarButton
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ p: ".6rem", height: 45 }}
                    onClick={handleCartAmountChange(cartItem?.qty - 1)}
                  >
                    <Remove fontSize="small" />
                  </BazaarButton>

                  <H3 fontWeight="600" mx={2.5}>
                    {cartItem?.qty.toString().padStart(2, "0")}
                  </H3>

                  <BazaarButton
                    size="small"
                    color="primary"
                    variant="outlined"
                    sx={{ p: ".6rem", height: 45 }}
                    onClick={handleCartAmountChange(cartItem?.qty + 1)}
                  >
                    <Add fontSize="small" />
                  </BazaarButton>
                </FlexBox>
              )} */}
            </Grid>
          </Grid>
        </ContentWrapper>

        <IconButton
          sx={{ position: "absolute", top: 3, right: 3 }}
          onClick={handleCloseDialog}
        >
          <Close fontSize="small" color="secondary" />
        </IconButton>
      </DialogContent>
    </Dialog>
  );
};

export default ProductViewDialog;
