import { Chip, styled } from "@mui/material";
import { FC } from "react";
import { Product } from "~/models";

const StyledChip = styled(Chip)(() => ({
  zIndex: 1,
  // top: "5px",
  // right: "5px",
  paddingLeft: 0,
  paddingRight: 0,
  fontWeight: 600,
  fontSize: "11px",
  // position: "absolute",
  borderRadius: "5px",
  transitionProperty: "transform",
  transitionDuration: "0.3s",
  transitionTimingFunction: "ease-in-out",
}));

type ProductLabelsProps = {
  product: Product;
  intro: boolean;
};
// ========================================================

const ProductLabels: FC<ProductLabelsProps> = ({ product, intro }) => {
  const madeInMng = product.attributes.find(
    (item) => item.label === "Made in Mongolia"
  );
  const isMadeInMng = madeInMng
    ? madeInMng.value === "Тийм"
      ? true
      : false
    : false;

  const withGift = product.attributes.find((item) => item.label === "Бэлэгтэй");
  const isWithGift = withGift
    ? withGift.value === "Тийм"
      ? true
      : false
    : false;

  const voucher = product.attributes.find((item) => item.label === "Ваучер");
  const isVoucher = voucher ? (voucher.value === "Тийм" ? true : false) : false;
  const eco = product.attributes.find((item) => item.label === "Эко");
  const isEco = eco ? (eco.value === "Тийм" ? true : false) : false;
  const bestseller = product.attributes.find(
    (item) => item.label === "Бестселлер"
  );
  const isBestseller = bestseller
    ? bestseller.value === "Тийм"
      ? true
      : false
    : false;
  const imported = product.attributes.find(
    (item) => item.label === "Import by Nomin"
  );
  const isImported = imported
    ? imported.value === "Тийм"
      ? true
      : false
    : false;
  const lastone = product.attributes.find((item) => item.label === "Last One");
  const isLastone = lastone ? (lastone.value === "Тийм" ? true : false) : false;
  const special = product.attributes.find(
    (item) => item.label === "Онцгой бараа"
  );
  const isSpecial = special ? (special.value === "Тийм" ? true : false) : false;
  const newProduct = product.attributes.find((item) => item.label === "Шинэ");
  const isNewProduct = newProduct
    ? newProduct.value === "Тийм"
      ? true
      : false
    : false;

  const savesaleoutlet = product.attributes.find(
    (item) => item.label === "Save Sale Outlet"
  );
  const isSavesaleoutlet = savesaleoutlet
    ? savesaleoutlet.value === "Тийм"
      ? true
      : false
    : false;
  const pocket = product.attributes.find((item) => item.label === "Pocket");
  const isPocket = pocket ? (pocket.value === "Тийм" ? true : false) : false;

  const valentine = product.attributes.find(
    (item) => item.label === "Валентин"
  );
  const isValentine = valentine
    ? valentine.value === "Тийм"
      ? true
      : false
    : false;

  const plusOne = product.giftProducts
    ? product.giftProducts.length !== 0
      ? product.giftProducts[0].sku === product.sku
        ? true
        : false
      : false
    : false;
  const plusOneCondition = product.giftProducts
    ? product.giftProducts.length !== 0
      ? product.giftProducts[0].giftCondition
      : 0
    : 0;
  const plusOneQty = product.giftProducts
    ? product.giftProducts.length !== 0
      ? product.giftProducts[0].qty
      : 0
    : 0;
  return intro ? (
    <>
      {plusOne && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #A44EA0",
            color: "#A44EA0",
            backgroundColor: "#FFF",
            fontSize: "12px",
            fontWeight: 800,
          }}
          label={
            <div className="content">
              <div className="hovered">{`${plusOneCondition} + ${plusOneQty}`}</div>
            </div>
          }
        />
      )}
      {isValentine && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #FF6F6F",
            color: "#FF6F6F",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="hovered">Валентин</div>
            </div>
          }
        />
      )}
      {isWithGift && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #A44EA0",
            color: "#A44EA0",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/beleg.svg"}
                  alt="бэлэг"
                  style={{ marginRight: "2px" }}
                />
                БЭЛЭГ
              </div>
            </div>
          }
        />
      )}
      {isEco && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #83C84D",
            color: "#83C84D",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/eco.svg"}
                  alt="eco"
                  style={{ marginRight: "2px" }}
                />
                ECO
              </div>
            </div>
          }
        />
      )}
      {isMadeInMng && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #5F8AD2",
            color: "#5F8AD2",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/mim.svg"}
                  alt="mim"
                  style={{ marginRight: "2px" }}
                />
                MADE IN MONGOLIA
              </div>
            </div>
          }
        />
      )}
      {isBestseller && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #EE5527",
            color: "#EE5527",
            backgroundColor: "#FFF",
            fontSize: "9px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/bestseller.svg"}
                  alt="bs"
                  style={{ marginRight: "2px" }}
                />
                BESTSELLER
              </div>
            </div>
          }
        />
      )}
      {isImported && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #3B68B2",
            color: "#3B68B2",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/ibn.svg"}
                  alt="ibn"
                  style={{ marginRight: "2px" }}
                />
                <div>
                  imported
                  <em>by NOMIN</em>
                </div>
              </div>
            </div>
          }
        />
      )}
      {isLastone && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #FE2C31",
            color: "#FE2C31",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/lastone.svg"}
                  alt="last"
                  style={{ marginRight: "2px" }}
                />
                LAST
              </div>
            </div>
          }
        />
      )}
      {isSpecial && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #47A4BB",
            color: "#47A4BB",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/ontsgoi.svg"}
                  alt="eco"
                  style={{ marginRight: "2px" }}
                />
                ОНЦГОЙ
              </div>
            </div>
          }
        />
      )}
      {isNewProduct && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #13DAFF",
            color: "#13DAFF",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/shine.svg"}
                  alt="new"
                  style={{ marginRight: "2px" }}
                />
                ШИНЭ
              </div>
            </div>
          }
        />
      )}
      {isSavesaleoutlet && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #F97D0B",
            color: "#F97D0B",
            backgroundColor: "#FFF",
            fontSize: "9px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/savesale.svg"}
                  alt="savesale"
                  style={{ marginRight: "2px" }}
                />
                SAVE SALE OUTLET
              </div>
            </div>
          }
        />
      )}
      {isVoucher && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #064492",
            color: "#064492",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/voucher.svg"}
                  alt="voucher"
                  style={{ marginRight: "2px" }}
                />
                VOUCHER
              </div>
            </div>
          }
        />
      )}
      {isPocket && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #EE2B53",
            color: "black",
            backgroundColor: "#FFF",
            fontSize: "12px",
          }}
          label={
            <div className="content">
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/pocket.svg"}
                  alt="voucher"
                  style={{ marginRight: "2px" }}
                />
                pocket
              </div>
            </div>
          }
        />
      )}
    </>
  ) : (
    <>
      {plusOne && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #A44EA0",
            color: "#A44EA0",
            backgroundColor: "#FFF",
            fontSize: "12px!important",
            fontWeight: 800,
          }}
          label={
            <div className="content">
              <div className="initial">{`+ ${plusOneQty}`}</div>
              <div className="hovered">{`${plusOneCondition} + ${plusOneQty}`}</div>
            </div>
          }
        />
      )}
      {isValentine && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #FF6F6F",
            color: "#FF6F6F",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/valentine.svg"}
                  alt="бэлэг"
                />
              </div>
              <div className="hovered">Валентин</div>
            </div>
          }
        />
      )}
      {isWithGift && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #A44EA0",
            color: "#A44EA0",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/beleg.svg"}
                  alt="бэлэг"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/beleg.svg"}
                  alt="бэлэг"
                  style={{ marginRight: "2px" }}
                />
                БЭЛЭГ
              </div>
            </div>
          }
        />
      )}
      {isEco && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #83C84D",
            color: "#83C84D",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img src={"/assets/images/product-labels/eco.svg"} alt="eco" />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/eco.svg"}
                  alt="eco"
                  style={{ marginRight: "2px" }}
                />
                ECO
              </div>
            </div>
          }
        />
      )}
      {isMadeInMng && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #5F8AD2",
            color: "#5F8AD2",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img src={"/assets/images/product-labels/mim.svg"} alt="mim" />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/mim.svg"}
                  alt="mim"
                  style={{ marginRight: "2px" }}
                />
                MADE IN MONGOLIA
              </div>
            </div>
          }
        />
      )}
      {isBestseller && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #EE5527",
            color: "#EE5527",
            backgroundColor: "#FFF",
            fontSize: "9px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/bestseller.svg"}
                  alt="bs"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/bestseller.svg"}
                  alt="bs"
                  style={{ marginRight: "2px" }}
                />
                BESTSELLER
              </div>
            </div>
          }
        />
      )}
      {isImported && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #3B68B2",
            color: "#3B68B2",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img src={"/assets/images/product-labels/ibn.svg"} alt="ibn" />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/ibn.svg"}
                  alt="ibn"
                  style={{ marginRight: "2px" }}
                />
                <div>
                  imported
                  <em>by NOMIN</em>
                </div>
              </div>
            </div>
          }
        />
      )}

      {isLastone && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #FE2C31",
            color: "#FE2C31",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/lastone.svg"}
                  alt="last"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/lastone.svg"}
                  alt="last"
                  style={{ marginRight: "2px" }}
                />
                LAST
              </div>
            </div>
          }
        />
      )}

      {isSpecial && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #47A4BB",
            color: "#47A4BB",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/ontsgoi.svg"}
                  alt="eco"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/ontsgoi.svg"}
                  alt="eco"
                  style={{ marginRight: "2px" }}
                />
                ОНЦГОЙ
              </div>
            </div>
          }
        />
      )}

      {isNewProduct && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #13DAFF",
            color: "#13DAFF",
            backgroundColor: "#FFF",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/shine.svg"}
                  alt="new"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/shine.svg"}
                  alt="new"
                  style={{ marginRight: "2px" }}
                />
                ШИНЭ
              </div>
            </div>
          }
        />
      )}
      {isSavesaleoutlet && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #F97D0B",
            color: "#F97D0B",
            backgroundColor: "#FFF",
            fontSize: "9px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/savesale.svg"}
                  alt="savesale"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/savesale.svg"}
                  alt="savesale"
                  style={{ marginRight: "2px" }}
                />
                SAVE SALE OUTLET
              </div>
            </div>
          }
        />
      )}

      {isVoucher && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #064492",
            color: "#064492",
            backgroundColor: "#FFF",
            fontSize: "10px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/voucher.svg"}
                  alt="voucher"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/voucher.svg"}
                  alt="voucher"
                  style={{ marginRight: "2px" }}
                />
                VOUCHER
              </div>
            </div>
          }
        />
      )}
      {isPocket && (
        <StyledChip
          className="label-controller"
          size="small"
          variant="outlined"
          sx={{
            border: "1px solid #EE2B53",
            color: "black",
            backgroundColor: "#FFF",
            fontSize: "12px",
          }}
          label={
            <div className="content">
              <div className="initial">
                <img
                  src={"/assets/images/product-labels/pocket.svg"}
                  alt="voucher"
                />
              </div>
              <div className="hovered">
                <img
                  src={"/assets/images/product-labels/pocket.svg"}
                  alt="voucher"
                  style={{ marginRight: "2px" }}
                />
                pocket
              </div>
            </div>
          }
        />
      )}
    </>
  );
};
export default ProductLabels;
